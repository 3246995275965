<!--
 * @Description: 快递100公司
 * @Author: 琢磨先生
 * @Date: 2022-07-20 20:59:29
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-02 09:26:45
-->


<template>
  <el-card class="box">
    <el-form ref="query" :model="query" :inline="true">
      <el-form-item label="关键字">
        <el-input v-model="query.q" placeholder="请输入名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="query.is_stop" clearable placeholder style="width: 120px">
          <el-option label="正常" :value="false"></el-option>
          <el-option label="停用" :value="true"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
    <!-- <el-button type="primary" size="small" @click="onEdit()" round icon="plus">新增</el-button> -->
  </el-card>
  <el-card class="box data">
    <el-table v-loading="loading" :data="tableData.data" border>
      <el-table-column label="编号" prop="id" width="100"></el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="编码" prop="code"></el-table-column>
      <el-table-column label="停用" width="180">
        <template #default="scope">
          <el-link type="danger" :underline="false" v-if="scope.row.is_stop">停用</el-link>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template #default="scope">
          <el-popconfirm
            v-if="!scope.row.is_super"
            :title="`您确定要${scope.row.is_stop?'启用':'停用'}?`"
            @confirm="onStopChange(scope.row)"
          >
            <template #reference>
              <el-button size="small" link type="primary" v-if="scope.row.is_stop">启用</el-button>
              <el-button size="small" link type="danger" v-else>停用</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      //显示弹框
      visibleDialog: false,
      saving: false,
      title: "",
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 查询搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.current = null;
      this.$http.post(`admin/v1/logistics`, this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 启用、停用
     */
    onStopChange(item) {
      this.$http.get("admin/v1/logistics/change?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.loadData();
        }
      });
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/admin/v1/expressCompany/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadData();
                this.visibleDialog = false;
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>